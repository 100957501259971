<template>
  <div class="exam" ref="exam">
    <div class="opacity-div" v-if="subnavShowHide" @click="closeMenu"></div>
    <div class="exam__sel">
      <dl>
        <dt :class="[checkNavTit == '客户' ? 'active' : '']" @click="navTitChange('客户')">
          <span> 客户 <i class="el-icon-arrow-down"></i> </span>
          <div class="sub__nav">
            <div v-for="item in navData[0]" :key="item.key" :class="[item.key == checkCustom.key ? 'active' : '']" @click="navCheckChange(item)">
              {{ item.Name }}
            </div>
          </div>
        </dt>
        <dt :class="[checkNavTit == '业务' ? 'active' : '']" @click="navTitChange('业务')">
          <span> 业务 <i class="el-icon-arrow-down"></i> </span>
          <div class="sub__nav">
            <div v-for="item in navData[1]" :key="item.key" :class="[item.key == checkBusiness.key ? 'active' : '']" @click="navCheckChange(item)">
              {{ item.Name }}
            </div>
          </div>
        </dt>
      </dl>
    </div>
    <div class="exam__imglist">
      <div class="not-data" v-if="caseInfo.length == 0">暂无数据</div>
      <exam-imglist v-show="caseInfo.length > 0" :caseInfo="caseInfo"></exam-imglist>
    </div>
  </div>
</template>

<script>
import { examImglist } from '@/components/app/examImglist';
import axios from 'axios';
export default {
  data() {
    return {
      navData: [],
      caseInfo: [],
      subnavShowHide: false,
      checkNavTit: '',
      checkCustom: { id: '', key: '' },
      checkBusiness: { id: '', key: '' }
    };
  },
  components: { examImglist },
  mounted() {
    this.getSwiperData();
    this.getCustoms();
    this.getBusiness();
  },
  methods: {
    navTitChange(item) {
      this.checkNavTit = item == this.checkNavTit ? '' : item;
      this.subnavShowHide = this.checkNavTit != '';
      if (this.subnavShowHide) {
        this.$refs.exam.style.overflow = 'hidden';
        this.$refs.exam.style.flex = '1';
      } else this.$refs.exam.style.overflow = 'auto';
    },
    closeMenu() {
      this.checkNavTit = '';
      this.subnavShowHide = false;
      this.$refs.exam.style.overflow = 'auto';
    },
    getSwiperData() {
      const params = { PageIndex: 1, PageSize: 12 };
      if (this.checkCustom.id) {
        params.CustomerId = this.checkCustom.id;
      }
      if (this.checkBusiness.id) {
        params.BusinessTypeId = this.checkBusiness.id;
      }
      axios.get('/api/Cases/Page', { params }).then(rs => {
        this.caseInfo = [];
        if (rs.data.Success) {
          let rows = rs.data.Data.Data;
          rows.forEach(ele => {
            const { Id: id, Title: text, Subtitle: subText, PhoneImgAddress: img } = ele;
            this.caseInfo.push({ ...ele, id, img, text, subText });
          });
        }
      });
    },
    async getCustoms() {
      const params = { Name: '', PageIndex: 1, PageSize: 999 };
      await axios.get('/api/Customer/Page', { params }).then(rs => {
        if (rs.data.Success) {
          let rows = rs.data.Data.Data || [];
          rows.forEach(element => {
            element.key = `custom_${element.Id}`;
          });
          this.navData[0] = rows;
        }
      });
    },
    async getBusiness() {
      await axios.get('/api/BusinessType?Name').then(rs => {
        if (rs.data.Success) {
          let rows = rs.data.Data || [];
          rows.forEach(element => {
            element.key = `buType_${element.Id}`;
          });
          this.navData[1] = rows;
        }
      });
    },
    navCheckChange(item) {
      if (this.checkNavTit == '客户') {
        this.checkCustom.id = this.checkCustom.id == item.Id ? '' : item.Id;
        this.checkCustom.key = this.checkCustom.key == item.key ? '' : item.key;
      } else if (this.checkNavTit == '业务') {
        this.checkBusiness.id = this.checkBusiness.id == item.Id ? '' : item.Id;
        this.checkBusiness.key = this.checkBusiness.key == item.key ? '' : item.key;
      }

      this.getSwiperData();
    }
  }
};
</script>

<style lang="scss" scoped>
.exam {
  &__sel {
    position: relative;
    margin-bottom: 5px;
    padding: 0 3px;
    background: $red;
    z-index: 18;

    dl {
      position: relative;
      display: flex;
      margin: 0;

      dt {
        padding: 0 12px;
        line-height: 44px;
        font-size: 16px;
        color: #333;
        & > span > i {
          transform-origin: center;
          transition: all 0.3s;
        }

        &.active {
          color: #fff;

          & > span > i {
            transform: rotate(180deg);
          }

          & > div {
            z-index: 99;
            opacity: 1;
            transform: scaleY(1);
            overflow-y: auto;
          }
        }
      }

      .sub__nav {
        position: absolute;
        left: -3px;
        right: -3px;
        top: 100%;
        max-height: 330px;
        opacity: 0;
        z-index: -1;
        overflow: hidden;
        transform: scaleY(0);
        transform-origin: left top;
        transition: opacity 0.2s, transform 0.3s, z-index 0.1s 0.1s;

        & > div {
          padding: 0 30px;
          color: #999999;
          background-color: #444444;
          border-bottom: 1px solid #5e5e5e;
          font-size: 14px;
          transition: all 0.3s;

          &.active,
          &:hover,
          &:focus {
            color: $red;
            background-color: #333333;
            border-bottom-color: $red;
          }
        }
      }
    }
  }

  &__imglist {
    padding: 0 15px 15px;
  }

  @media (min-width: 768px) {
    &__sel {
      margin-bottom: 10px;
      padding: 0 6px;
      dl {
        dt {
          padding: 0 24px;
          line-height: 88px;
          font-size: 32px;
        }
        .sub__nav {
          left: -6px;
          right: -6px;
          transform: translateY(-180px) scaleY(0.3);
          & > div {
            padding: 0 60px;
            font-size: 28px;
          }
        }
      }
    }

    &__imglist {
      padding: 0 30px 30px;
    }
  }
}
.opacity-div {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}
.not-data {
  min-height: 300px;
}
</style>
